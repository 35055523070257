import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-size-search',
  templateUrl: './table-size-search.component.html',
  styleUrls: ['./table-size-search.component.scss']
})
export class TableSizeSearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
