import {
    ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Inject,
    Input, Output, OnInit, OnDestroy, Directive
} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable, Subject, Subscription} from 'rxjs';
import { filter, skip, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { EDITABLE_CONFIG } from './editable.config';
import {EditableConfig} from './editable.config';
import {Mode} from './mode';
import {TriggerEvents} from './editable.types';
import {ViewModeDirective} from './directives/view-mode.directive';
import {EditModeDirective} from './directives/edit-mode.directive';

@Component({
    selector: 'ngx-inline-edit',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [':host {cursor: pointer;}'],
    template: '<ng-container *ngTemplateOutlet="(editMode$ | async) ? ((editModeTpl !== undefined) ? editModeTpl.tpl : viewModeTpl.tpl) : viewModeTpl.tpl"></ng-container>',
})

export class EditableComponent implements OnInit, OnDestroy{

    @Input() openOn: TriggerEvents;
    @Input() closeOn: TriggerEvents;
    @Output() save: EventEmitter<void>;
    @Output() cancel: EventEmitter<void>;
    @Output() onStateChange: EventEmitter<Mode>;

    @ContentChild(ViewModeDirective)
    viewModeTpl: ViewModeDirective;

    @ContentChild(EditModeDirective)
    editModeTpl: EditModeDirective;

    editMode;
    editMode$: Observable<boolean>;
    viewHandler: Subscription;
    editHandler: Subscription;
    private destroy$;
    isGrouped: boolean;

    constructor(private el: ElementRef, @Inject(EDITABLE_CONFIG) private config: EditableConfig) {
        this.openOn = config.openOn;
        this.closeOn = config.closeOn;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        this.onStateChange = new EventEmitter();
        this.editMode = new BehaviorSubject(false);
        this.editMode$ = this.editMode.asObservable();
        this.destroy$ = new Subject();
        this.isGrouped = false;
    }

    get element() {
        return this.el.nativeElement;
    }

    ngOnInit() {
        this.handleViewMode();
        this.handleEditMode();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
    }

    handleViewMode() {
        this.viewHandler = fromEvent(this.element, this.openOn)
            .pipe(withLatestFrom(this.editMode$), filter(([_, editMode]) => !editMode), takeUntil(this.destroy$))
            .subscribe(() => this.displayEditMode());
    }

    handleEditMode() {
        const clickOutside$ = (editMode) => fromEvent(document, this.closeOn).pipe(filter(() => editMode),
        /*
        skip the first propagated event if there is a nested node in the viewMode templateRef
        so it doesn't trigger this eventListener when switching to editMode
         */
        skip(this.openOn === this.closeOn ? 1 : 0), filter(({ target }) => this.element.contains(target) === false), take(1));
        this.editHandler = this.editMode$
            .pipe(switchMap((editMode) => clickOutside$(editMode)), takeUntil(this.destroy$))
            .subscribe(() => this.saveEdit());
    }

    displayEditMode() {
        this.editMode.next(true);
        this.onStateChange.emit('edit');
    }

    saveEdit() {
        this.save.next();
        this.leaveEditMode();
    }

    cancelEdit() {
        this.cancel.next();
        this.leaveEditMode();
    }

    leaveEditMode() {
        this.editMode.next(false);
        this.onStateChange.emit('view');
        if (!this.isGrouped) {
            this.viewHandler.unsubscribe();
            setTimeout(() => this.handleViewMode(), 0);
        }
    }
}
