import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';

import { SanitizeHtmlPipe } from 'src/app/core/pipes/sanitize-html.pipe';
import {NotificationService} from "../../../core/services/notification.service";
import {NotificationChannels, NotificationPreferences, Notifications, NotificationTypes} from "../../../core/models/notification.model";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'notifications-subscribe',
    styleUrls: ['./notifications-subscribe.component.scss'],
    templateUrl: './notifications-subscribe.component.html',
})

export class NotificationsSubscribeComponent implements OnInit {
    @Input() notificationType: string;
    @Input() notificationId: string;
    @Input() typeText: string = "all";

    validationSubs: UntypedFormGroup;

    public notifications: NotificationPreferences[];
    public currentNotification: NotificationPreferences;

    public email = false;
    public dashboard = false

    public updatedState = false;

    constructor(private notificationService: NotificationService, public formBuilder: UntypedFormBuilder) {
    }

    ngOnInit(): void {

        // Validate new ticket submitting
        this.validationSubs = this.formBuilder.group({
            dashboard_notf: [this.dashboard],
            email_notf: [this.email],
        });

        this.notificationService.preferences().subscribe((data) => {
            if(data != null){
                this.notifications = data.data;

                for(let notification of this.notifications){
                    if(notification.type == this.notificationType + "_" + this.notificationId)
                    {
                        this.email = notification.channel.includes(NotificationChannels.Mail);
                        this.dashboard = notification.channel.includes(NotificationChannels.Database);
                        this.currentNotification = notification;

                        this.validationSubs = this.formBuilder.group({
                            dashboard_notf: [this.dashboard],
                            email_notf: [this.email],
                        });

                        break;
                    }
                }
            }
        });
    }

    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    updatePreferences(){
        (async () => {
            // Waiting until validator has update with values after (click) event
            await this.delay(1000);

            // What channels to update to
            let channels : NotificationChannels[] = [];

            // Do they want dashboard updates?
            if(this.validationSubs.get('dashboard_notf').value)
            {
                channels.push(NotificationChannels.Database);
                channels.push(NotificationChannels.Broadcast);
            }

            // Do they want email updates?
            if(this.validationSubs.get('email_notf').value)
            {
                channels.push(NotificationChannels.Mail);
            }

            // Are there any channels selected
            if(channels.length == 0)
            {
                // Remove preferences
                this.notificationService.configPreferences(this.notificationType, false, this.notificationId)
                    .subscribe((data) => {
                        this.updatedState = true;
                    });
            }else{
                // Add preferences
                this.notificationService.configPreferences(this.notificationType, true, this.notificationId, channels)
                    .subscribe((data) => {
                        this.updatedState = true;
                    });
            }
        })();
    }
}
