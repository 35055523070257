import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {DeviceStatus} from "../models/smartcsc";

@Pipe({
    name: 'deviceConnectionStatus'
})
export class DeviceConnectionStatus implements PipeTransform {
    transform(time: Date): DeviceStatus {
        if(time == null)
            return DeviceStatus.Unknown

        let formattedDate = (moment(time)).format('DD-MMM-YYYY HH:mm:ss')
        var duration = moment.duration(moment.utc().diff(formattedDate));

        if(duration.asMinutes() <= 15)
            return DeviceStatus.Online;

        return DeviceStatus.Offline;
    }
}
