import {Directive, ElementRef, OnDestroy, Input, OnInit} from '@angular/core';
import { EditableComponent } from '../editable.component';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[editableOnCancel]'
})

export class EditableCancelDirective implements OnInit, OnDestroy{

    @Input() cancelEvent: string;
    destroy: Subject<any>;

    constructor(public editable: EditableComponent, public el: ElementRef) {
        this.editable = editable;
        this.el = el;
        this.cancelEvent = 'click';
        this.destroy = new Subject();
    }

    ngOnInit() {
        fromEvent(this.el.nativeElement, this.cancelEvent)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => this.editable.cancelEdit());
    }

    ngOnDestroy() {
        this.destroy.next(true);
    }
}
