import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import {DeviceStatus} from "../models/smartcsc";

@Pipe({
    name: 'deviceConnectionTimeAgo'
})
export class DeviceConnectionTimeAgo implements PipeTransform {
    transform(time: Date): string {
        if(time == null)
            return 'Unknown';

        return moment.utc(time).fromNow();
    }
}
