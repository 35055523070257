import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UIModule} from './ui/ui.module';

import {WidgetModule} from './widget/widget.module';
import {VarDirective} from './directive/ng-var.directive';
import {CanDirective} from './directive/can.directive';
import {CanWithOwnerDirective} from './directive/canWithOwner.directive';
import {ViewModeDirective} from './editable/directives/view-mode.directive';
import {EditModeDirective} from './editable/directives/edit-mode.directive';
import {EditableComponent} from './editable/editable.component';
import {EditableFocusDirective} from './editable/directives/editable-focus.directive';
import {EditableGroupDirective} from './editable/directives/editable-group.directive';
import {EditableGroupActionDirective} from './editable/directives/editable-group-action.directive';
import {EditableGroupCancelDirective} from './editable/directives/editable-group-cancel.directive';
import {EditableGroupEditDirective} from './editable/directives/editable-group-edit.directive';
import {EditableGroupSaveDirective} from './editable/directives/editable-group-save.directive';
import {EditableOnEnterDirective} from './editable/directives/editable-on-enter.directive';
import {EditableOnEscapeDirective} from './editable/directives/editable-on-escape.directive';
import {EditableSaveDirective} from './editable/directives/editable-save.directive';
import {EditableCancelDirective} from "./editable/directives/editable-cancel.directive";
import {EditableConfig} from "./editable/editable.config";

@NgModule({
    declarations: [
        VarDirective,
        CanDirective,
        CanWithOwnerDirective,
        ViewModeDirective,
        EditModeDirective,
        EditableCancelDirective,
        EditableFocusDirective,
        EditableGroupDirective,
        EditableGroupActionDirective,
        EditableGroupCancelDirective,
        EditableGroupEditDirective,
        EditableGroupSaveDirective,
        EditableOnEnterDirective,
        EditableOnEscapeDirective,
        EditableSaveDirective,
        EditableComponent
    ],
    imports: [
        CommonModule,
        UIModule,
        WidgetModule
    ],
    providers: [
        EditableConfig
    ],
    exports: [
        VarDirective,
        CanDirective,
        CanWithOwnerDirective,
        ViewModeDirective,
        EditModeDirective,
        EditableCancelDirective,
        EditableFocusDirective,
        EditableGroupDirective,
        EditableGroupActionDirective,
        EditableGroupCancelDirective,
        EditableGroupEditDirective,
        EditableGroupSaveDirective,
        EditableOnEnterDirective,
        EditableOnEscapeDirective,
        EditableSaveDirective,
        EditableComponent
    ]
})

export class SharedModule {
}
