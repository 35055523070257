<div *ngIf="status" style="position: fixed;
            width: 100%;
            background-color: rgba(255, 255, 255, 1);
            z-index: 2;
            height: 100%;
            margin-left: -12px;
            margin-top: -23px;">
    <div style="width: 150px;
                height: 150px;
                margin-left: calc(50vw - 150px);
                margin-top: calc(50vh - 100px);
                text-align: center;
                font-size: 20px;">
        <div class="spinner-border text-success m-1" role="status" style="width: 100px; height: 100px; border-width: 5px;">
            <span class="sr-only">Loading...</span>
        </div>
        Loading....
    </div>
</div>
