import {Injectable} from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {AwsCognitoAuthService} from '../services/aws-cognito-auth.service';

import {environment} from '../../../environments/environment';

import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://7c534f733a2670114bc9519c4e7fe0fa@o4507890819858432.ingest.de.sentry.io/4507891039207504",
    integrations: [
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: "system",
            onFormOpen: () => {
                document.querySelector("#sentry-feedback").shadowRoot.querySelector(".brand-link").remove();
            },
        }),
    ],
});

@Injectable({providedIn: 'root'})
export class AuthGuard  {
    constructor(
        private router: Router,
        private authenticationService: AwsCognitoAuthService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // Handle environments using aws cognito
        if (environment.defaultauth === 'awsCognito') {
            let isAuth = this.authenticationService.isLoggedIn();
            if(isAuth) {
                return true;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/account/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
