import { Address } from './address.models';
import { Company } from './company.models';

export class Permission {
    id: number;
    name: string;
}

export class Role {
    id: number;
    name: string;
    permissions: Permission[];

    constructor()
    {
        this.permissions = Array(new Permission);
    }
}

export class User {
    id: number;
    username?: string;
    phone?: string;
    first_name?: string;
    last_name?: string;
    job_title?: string;
    email: string;
    address_id: number;
    address: Address;
    company_id: number;
    company: Company;
    roles: Role[];
    permissions?: any;

    constructor()
    {
        this.address = new Address();
        this.roles = Array(new Role);
        this.company = new Company;
    }
}
