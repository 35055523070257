import {Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable} from 'rxjs';

import {AwsCognitoAuthService} from '../services/aws-cognito-auth.service';

import {environment} from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AwsCognitoAuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Handle environments using aws cognito
        if (environment.defaultauth === 'awsCognito') {
            // Get the jwt token
            const token = this.authenticationService.getJwtToken();

            // Only load jwt token into visive api requests
            try {
                let domain = (new URL(request.url));
                let host = domain.host.replace('www.','')

                if (token != "" && host == environment.jwtHostUrl) {
                    // Load token into the request
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                }
            }catch (_){
                return next.handle(request);
            }
        }
        return next.handle(request);
    }
}
