import {Directive, TemplateRef} from '@angular/core';

@Directive({
    selector: '[view]'
})

export class ViewModeDirective {
    constructor(public tpl: TemplateRef<any>){
    }
}
