import {Directive, ElementRef, ContentChildren, EventEmitter, Output, QueryList, AfterViewInit} from '@angular/core';
import { EditableComponent } from '../editable.component';
import {Mode} from '../mode';

@Directive({
    selector: '[editableGroup]'
})

export class EditableGroupDirective implements AfterViewInit {

    @ContentChildren(EditableComponent)
    children: QueryList<EditableComponent>;

    @Output() save: EventEmitter<void>;
    @Output() cancel: EventEmitter<void>;
    @Output() editableModeChange: EventEmitter<Mode>;

    constructor(public group: EditableGroupDirective) {
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        this.editableModeChange = new EventEmitter();
    }

    ngAfterViewInit() {
        this.children.forEach((child) => (child.isGrouped = true));
        this.children.forEach((child) => child.viewHandler.unsubscribe());
        this.children.forEach((child) => child.editHandler.unsubscribe());
    }

    displayEditMode() {
        this.editableModeChange.emit('edit');
        this.children.forEach((child) => child.displayEditMode());
    }

    saveEdit() {
        this.editableModeChange.emit('view');
        this.children.forEach((child) => child.saveEdit());
        this.save.emit();
    }

    cancelEdit() {
        this.editableModeChange.emit('view');
        this.children.forEach((child) => child.cancelEdit());
        this.cancel.emit();
    }
}
