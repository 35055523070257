import { throwIfEmpty } from "rxjs/operators";
import { Address } from "./address.models";


export interface CompanyUserInvite {
    id: number;
    email: string;
    token: string;
    company_name: string;
    role: {
        id: number,
        name: string,
    }|null;
    created_at: Date;
    updated_at: Date;
}

export interface CompanyUserPublicInvite {
    company_name: string,
    email: string,
    token: string
    created_at: Date,
    updated_at: Date,
}

export interface PartnerInvitePublicInvite {
    email: string,
    token: string,
    type: string,
    from: {
        name: string,
    },
    created_at: Date,
    updated_at: Date,
}

export class Company {
    id: number;
    public_id_token: string;
    name: string;
    phone: string;
    address: Address;
    admin_first_name: string;
    admin_last_name: string;
    admin_phone: string;
    admin_email: string;
    pivot: {
        view: boolean;
        modify: boolean;
        hw_modify: boolean;
        hw_add: boolean;
        hw_delete: boolean;
    };

    constructor(id = 0, name = "", phone = "")
    {
        this.id = id;
        this.name = name;
        this.phone = phone;
        this.address = new Address();
    }
}
