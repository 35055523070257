<div class="position-fixed top-0 end-0 p-3" style="z-index: 1005">
    <ngb-toast *ngIf="updatedState"
               [delay]="5000"
               [animation]="true"
               [autohide]="true"
               (mouseleave)="updatedState = false"
               (hidden)="updatedState = false"
               [class.bg-success]="true">
        <img src="assets/images/logo-light-small.png" alt="" class="me-2" height="18">
        <span class="me-auto" style="color: white">Notification preferences updated</span>
    </ngb-toast>
</div>

<div class="dropdown d-inline-block" ngbDropdown>
    <button type="button" class="btn btn-info btn-rounded" ngbDropdownToggle>
        <i class="mdi mdi mdi-bell d-none d-xl-inline-block"></i> Alerts
    </button>
    <form class="dropdown-menu dropdown-menu-end" [formGroup]="validationSubs" ngbDropdownMenu>
        <div class="m-3">
            Want to be notified of {{ typeText }} changes?
        </div>
        <div class="dropdown-divider"></div>
        <div class="form-check m-3" (click)="updatePreferences()">
            <input class="form-check-input" type="checkbox" id="dashboard_notf" name="dashboard_notf"
                   style="width: 20px; height: 20px;" formControlName="dashboard_notf">
            <label class="form-check-label" for="dashboard_notf"
                   style="padding-left: 10px; margin-top:3px;">
                {{ 'HEADER.NOTIFICATIONS.DASHBOARD' | translate}}
            </label>
        </div>
        <div class="dropdown-divider"></div>
        <div class="form-check m-3" (click)="updatePreferences()">
            <input class="form-check-input" type="checkbox" id="email_notf" name="email_notf"
                   style="width: 20px; height: 20px;" formControlName="email_notf">
            <label class="form-check-label" for="email_notf"
                   style="padding-left: 10px; margin-top:3px;" >
                {{ 'HEADER.NOTIFICATIONS.EMAIL' | translate}}
            </label>
        </div>
    </form>
</div>
