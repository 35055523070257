import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
    selector: '[editableFocusable]'
})

export class EditableFocusDirective implements OnInit{
    constructor(public el: ElementRef<any>){
    }

    ngOnInit() {
        this.el.nativeElement.focus();
    }
}
