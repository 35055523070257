import { Injectable } from '@angular/core';
import PusherJS from 'pusher-js';
import Echo from 'laravel-echo';
import {AwsCognitoAuthService} from './aws-cognito-auth.service';
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    public client: any;

    constructor(private authenticationService: AwsCognitoAuthService) {
        window['Pusher'] = PusherJS;
        const token = this.authenticationService.getJwtToken();
        this.client = new Echo({
            broadcaster: 'pusher',
            key: '247965b59f6762bc6f6a',
            forceTLS: true,
            cluster: 'eu',

            encrypted: false,
            disableStats: true,

            enabledTransports: ['ws', 'wss'],
            // wsHost: environment.webSocket,
            // wsPort: '6001',
            // wssPort: '6002',

            authEndpoint: environment.webSocketAuth,
            auth: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        });
    }

}
