import {SimpleTag} from "./tag.model";
import {Paginate} from "./paginate.model";
import {DesiredState, Energy, FadeEffect, SceneEventType} from "./scene.model";

export enum DeviceStatus
{
    Online,
    Offline,
    Unknown,
    Sleeping
}

export interface SmartCscBrightnesses
{
    enable_one: boolean,
    brightness_one: number,

    enable_two: boolean,
    brightness_two: number,

    enable_three: boolean,
    brightness_three: number,

    enable_four: boolean,
    brightness_four: number,
}

export interface SmartCscEvent{
    scene_event_id: number;
    scene_version: number;
    device_activity_id: number;
    devices_event_id: number;
    last_configured_at: Date;
    last_verified_at: Date;

    transaction_status: string;

    name: string;
    type: SceneEventType;
    start_seconds: number;
    end_seconds: number;
    color: string;
    running_days: any[];
    priority: number;
    is_default: boolean;
    parameters: DesiredState | FadeEffect;

    scene_id: number;
    scene_name: string;

    created_at: Date;
    updated_at: Date;
}

export interface SmartCscInfo {
    id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    serial_number: string;
    hardware_revision: string;
    firmware_revision: string;
    device_management: boolean;
    protocol: string;
    site: {
        id: number;
        name: string;
        company_id?: number;
        latitude?: number;
        longitude?: number;
    };
    events: SmartCscEvent[] | boolean;
    tags: SimpleTag[];
    current_readings?: param[] | boolean;
    type?: string;
    estimated_energy_saving: Energy;
    latitude?: number;
    longitude?: number;
}

export interface SmartCscMinimal {
    id: number;
    name: string;
    serial_number: string;
    company_id: number;
}

export interface SmartCscStatus {
    serial_number: string;
    online: boolean;
    datetime: Date;
}

type param = { [name: string]: any };

export interface SmartCscParams {
    serial_number: string;
    parameters: param;
    error_code: string;
    error_message: string;
}

export interface SmartCscParam {
    name: string;
    value: any;
}

export interface SmartCscTransaction {
    serial_number: string;
    transaction_id: string;
    error: string;
}


