import {Directive, HostListener, HostDecorator} from '@angular/core';
import { EditableGroupActionDirective } from './editable-group-action.directive';

@Directive({
    selector: '[editableOnEnter]',
})

export class EditableOnEnterDirective extends EditableGroupActionDirective {

    @HostListener('keyup.enter', ['$event'])
    onClick() {
        this.group.saveEdit();
    }
}
