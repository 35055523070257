import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';

import {PagetitleComponent} from './pagetitle/pagetitle.component';
import {EditorSafeOutputComponent} from './editor-safe-output/editor-safe-output.component';
import {SanitizeHtmlPipe} from '../../core/pipes/sanitize-html.pipe';
import {DROPZONE_CONFIG} from 'ngx-dropzone-wrapper';
import {PaginateComponent} from './paginate/paginate.component';
import { TableSizeSearchComponent } from './table-size-search/table-size-search.component';
import { LoadingComponent } from './loading/loading.component';
import {DeviceConnectionStatus} from "../../core/pipes/device-connection-status";
import {DeviceConnectionTimeAgo} from "../../core/pipes/device-connection-time-ago";
import {PrettyIsoTime} from "../../core/pipes/pretty-iso-time";
import {NotificationsSubscribeComponent} from "./notifications-subscribe/notifications-subscribe.component";
import {TranslateModule} from "@ngx-translate/core";
import {IsArrayWithLength} from "../../core/pipes/isArrayWithLength.pipe";
import {IsArrayWithLengthHasKey} from "../../core/pipes/isArrayWithLengthHasKey.pipe";
import {SemverGtPipe} from "../../core/pipes/semver-gt";

@NgModule({
    declarations: [
        PagetitleComponent,
        SanitizeHtmlPipe,
        EditorSafeOutputComponent,
        PaginateComponent,
        TableSizeSearchComponent,
        LoadingComponent,
        DeviceConnectionStatus,
        DeviceConnectionTimeAgo,
        PrettyIsoTime,
        NotificationsSubscribeComponent,
        IsArrayWithLength,
        IsArrayWithLengthHasKey,
        SemverGtPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbCollapseModule,
        NgbDatepickerModule,
        NgbTimepickerModule,
        NgbDropdownModule,
        TranslateModule,
        ReactiveFormsModule,
        NgbToastModule
    ],
    providers: [
        SanitizeHtmlPipe,
        IsArrayWithLength,
        IsArrayWithLengthHasKey,
        // SemverGtPipe,

        // DeviceConnectionStatus,
        // DeviceConnectionTimeAgo,
        // PrettyIsoTime

    ],
    exports: [
        PagetitleComponent,
        EditorSafeOutputComponent,
        PaginateComponent,
        LoadingComponent,
        DeviceConnectionStatus,
        DeviceConnectionTimeAgo,
        PrettyIsoTime,
        NotificationsSubscribeComponent,
        IsArrayWithLength,
        IsArrayWithLengthHasKey,
        SemverGtPipe
    ]
})
export class UIModule {
}
