<div class="row mb-md-2">
    <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">Show
                <select name="pageSize" aria-controls="tickets-table" class="form-control form-control-sm mx-2">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                </select>
                entries
            </label>
        </div>
    </div>
    <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
            <label class="d-inline-flex align-items-center">Search:
                <input type="text" name="searchTerm" aria-controls="tickets-table" class="form-control form-control-sm ms-2">
            </label>
        </div>
    </div>
</div>
