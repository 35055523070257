<div class="row" *ngIf="paginate">
    <div class="col">
        <div id="tickets-table_info" role="status" aria-live="polite" class="dataTables_info mb-2">
            Showing {{ paginate.from }} to {{ paginate.to }} of {{ paginate.total_records }} entries
        </div>
    </div>
    <div class="col">
        <ul class="pagination" style="float: right;">

            <ng-container *ngIf="paginate.current_page > 1">
                <li class="page-item" (click)="page(paginate.current_page - 1)">
                    <a aria-label="Previous" class="page-link">
                        <span>«</span>
                    </a>
                </li>
            </ng-container>

            <ng-container *ngIf="paginate.current_page <= 1">
                <li class="page-item disabled">
                    <a aria-label="Previous" href="" class="page-link" tabindex="-1" aria-disabled="true">
                        <span aria-hidden="true">«</span>
                    </a>
                </li>
            </ng-container>

            <ng-container *ngFor="let pageNumber of getPageArray(); let count = index">
                <ng-container *ngIf="paginate.current_page == pageNumber">
                    <li class="page-item active" aria-current="page">
                        <a class="page-link"> {{ pageNumber }} <span class="sr-only">(current)</span></a>
                    </li>
                </ng-container>
                <ng-container *ngIf="paginate.current_page != pageNumber">
                    <li class="page-item" (click)="page(pageNumber)">
                        <a class="page-link"> {{ pageNumber }} </a>
                    </li>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="paginate.current_page < (paginate.total_records / paginate.per_page)">
                <li class="page-item" (click)="page(paginate.current_page + 1)">
                    <a aria-label="Next" class="page-link">
                        <span>»</span>
                    </a>
                </li>
            </ng-container>

            <ng-container *ngIf="paginate.current_page >= (paginate.total_records / paginate.per_page)">
                <li class="page-item disabled">
                    <a aria-label="Next" href="" class="page-link" tabindex="-1" aria-disabled="true">
                        <span aria-hidden="true">»</span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>
</div>
