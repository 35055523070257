
import { Directive, Input, TemplateRef, ViewContainerRef, AfterContentInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { PermissionType } from '../../core/models/permission.model';
import {UserService} from '../../core/services/user.service';

@Directive({
    selector: '[canWithOwner]'
})

export class CanWithOwnerDirective implements AfterContentInit
{
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userServices: UserService,
        private cb: ChangeDetectorRef
    ){

    }

    private device = false;
    private invert = false;
    private data: any[];

    @Input()
    set canWithOwnerDevice(value: boolean) {
        this.device = value;
    }

    @Input()
    set canWithOwnerInvert(value: boolean) {
        this.invert = value;
    }

    @Input() set canWithOwner(s: any[]) {
        this.data = s;
    }

    ngAfterContentInit() {
        this.isGranted(this.data[0], this.data[1]);
        this.cb.detectChanges();
    }

    private isGranted(permissionInput: string, companyInput?: number) {
        // Get users permissions
        let companyId = this.userServices.getCurrentUsersCompanyId();
        let permissions = this.userServices.getCurrentUsersPermissions();
        if (permissions.length === 0 || companyId === 0){
            const user = this.userServices.getCurrentUser().subscribe((data) => {
                permissions = this.userServices.getCurrentUsersPermissions();
                companyId = this.userServices.getCurrentUsersCompanyId();
                this.isGranted(permissionInput);
            });
        }

        // Direct permission match e.g 'sites.update.45'
        const directPermission = Object.keys(permissions).filter((x) => {
            return permissions[x] === permissionInput;
        });
        const directMatchBool = (directPermission.length > 0);

        // Match parent permission e.g 'site.update'
        const heirMatch = permissions.filter((permissionString) => {
            return permissionInput.includes(permissionString);
        });
        let heirMatchBool = (heirMatch.length > 0);
        if (companyInput !== undefined){
            heirMatchBool = (heirMatch.length > 0 /*&& (companyInput === companyId)*/);
        }


        // Missing id
        const missing = permissionInput.slice(-1);
        if (missing === '.')
        {
            this.viewContainer.clear();
            return;
        }

        // Is a device direct permission
        if (this.device){
            const devicePermission = permissionInput.replace('site.hardware', 'devices.csc');
            const deviceMatch = permissions.filter((permissionString) => {
                return devicePermission.includes(permissionString);
            });

            if ((deviceMatch.length > 0) && (companyInput === companyId)){
                if (!this.invert) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }else{
                    this.viewContainer.clear();
                }
                return;
            }
        }

        // Hide or show element
        if (directMatchBool || (heirMatchBool /*&& companyInput === companyId*/)) {
            if (!this.invert) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }else{
                this.viewContainer.clear();
            }
        } else {
            if (!this.invert) {
                this.viewContainer.clear();
            }else{
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        }
    }
}
