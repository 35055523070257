import {Directive, TemplateRef} from '@angular/core';

@Directive({
    selector: '[edit]'
})

export class EditModeDirective {
    constructor(public tpl: TemplateRef<any>) {
    }
}
