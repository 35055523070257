import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';

import { SanitizeHtmlPipe } from 'src/app/core/pipes/sanitize-html.pipe';

@Component({
    selector: 'app-editor-safe-output',
    styleUrls: ['./editor-safe-output.component.scss'],
    template: '<div class="editor-safe-html" [innerHTML]="htmlCode | sanitizeHtml"></div>',
    encapsulation: ViewEncapsulation.None,
})

export class EditorSafeOutputComponent {
    @Input() htmlCode!: string;

    constructor(public safe : SanitizeHtmlPipe) {
    }
}
