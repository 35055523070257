import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { EditableComponent } from '../editable.component';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[editableOnEscape]',
})

export class EditableSaveDirective implements OnInit, OnDestroy{

    @Input() saveEvent: string;
    destroy$: Subject<boolean>;

    constructor(private editable: EditableComponent, private el: ElementRef) {
    }

    ngOnInit() {
        fromEvent(this.el.nativeElement, this.saveEvent)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.editable.saveEdit());
    }

    ngOnDestroy() {
        this.destroy$.next(true);
    }
}
