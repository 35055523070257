import {Directive, HostListener, HostDecorator} from '@angular/core';
import { EditableComponent } from '../editable.component';

@Directive({
    selector: '[editableOnEscape]',
})

export class EditableOnEscapeDirective{

    constructor(private editable: EditableComponent) {
    }

    @HostListener('keyup.escape', ['$event'])
    onEnter() {
        this.editable.cancelEdit();
    }
}
