import {AfterContentInit, ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import { PermissionType } from '../../core/models/permission.model';
import {UserService} from '../../core/services/user.service';

@Directive({
    selector: '[can]'
})

export class CanDirective implements AfterContentInit{
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private userServices: UserService,
        private cb: ChangeDetectorRef
    ) {

    }

    private and = true;
    private invert = false;
    private data: string;

    @Input() set canAnd(value: boolean) {
        this.and = value;
    }

    @Input() set canInvert(value: boolean) {
        this.invert = value;
    }

    @Input() set can(value: string) {
        this.data = value;
    }

    ngAfterContentInit() {
        this.isGranted(this.data);
        this.cb.detectChanges();
    }

    private isGranted(permissionInput: string) {
        if (permissionInput == null)
        {
            this.viewContainer.createEmbeddedView(this.templateRef);
            return;
        }

        // Is user super admin
        if (permissionInput === '*'){
            if (this.userServices.getCurrentUsersRoles().find(e => e.name === 'super-admin')){
                if (!this.invert) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                }else{
                    this.viewContainer.clear();
                }
                return;
            }
        }

        // Get users permissions
        let companyId = this.userServices.getCurrentUsersCompanyId();
        let permissions = this.userServices.getCurrentUsersPermissions();
        if (permissions.length === 0 || companyId === 0){
            const user = this.userServices.getCurrentUser().subscribe((data) => {
                permissions = this.userServices.getCurrentUsersPermissions();
                companyId = this.userServices.getCurrentUsersCompanyId();
                this.isGranted(permissionInput);
            });
        }

        // Direct permission match e.g 'site.update'
        const directPermission = Object.keys(permissions).filter((x) => {
            return permissions[x] == permissionInput;
        });

        // Match parent permission e.g 'site.update.45'
        const heirMatch = permissions.filter((permission_string) => {
            return permission_string.includes(permissionInput);
        });
        const heirMatchBool = (heirMatch.length > 0);

        // Missing id
        const missing = permissionInput.slice(-1);
        if (missing == '.')
        {
            this.viewContainer.clear();
            return;
        }

        // Hide or show element
        if ((directPermission.length > 0 || heirMatchBool) && this.and) {
            if (!this.invert) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }else{
                this.viewContainer.clear();
            }
        } else {
            if (!this.invert) {
                this.viewContainer.clear();
            }else{
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        }
    }
}
