import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isArrayWithLength'
})
export class IsArrayWithLength implements PipeTransform {

    constructor() {
    }

    transform(value) {
        if (typeof value !== 'boolean' && value.length > 0) {
            return true;
        }
        return false;
    }
}
