import { InjectionToken, Injectable } from '@angular/core';
import { TriggerEvents } from './editable.types';

@Injectable()
export class EditableConfig {
    public openOn?: TriggerEvents;
    public closeOn?: TriggerEvents;
}

export const DEFAULT_CONFIG = {
    openOn: 'click',
    closeOn: 'click',
};

export const EDITABLE_CONFIG = new InjectionToken('editable.config', {
    factory() {
        return DEFAULT_CONFIG;
    },
});
