import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        permissionString: ''
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx bx-home-circle',
        iconBgColor: '#45ab2e',
        iconColor: '#f5f5f5',
        link: '/dashboard',
    },
    {
        id: 5,
        label: 'MENUITEMS.ACCOUNT.TEXT',
        icon: 'bx bx-user-circle',
        iconBgColor: '#2c52c5',
        iconColor: '#f5f5f5',
        link: '/profile',
    },
    {
        id: 5,
        label: 'MENUITEMS.COMPANY.TEXT',
        icon: 'bx bxs-building-house',
        iconBgColor: '#ce9428',
        iconColor: '#f5f5f5',
        link: '/company',
        permissionString: 'company.read'
    },
    {
        id: 5,
        label: 'MENUITEMS.TICKETS.TEXT',
        icon: 'bx bxs-conversation',
        iconBgColor: '#d13d73',
        iconColor: '#f5f5f5',
        link: '/tickets',
    },
    {
        id: 5,
        label: 'MENUITEMS.PARAMMANAGER.TEXT',
        icon: 'bx bxs-tag',
        iconBgColor: '#EB720E',
        iconColor: '#f5f5f5',
        link: '/parameter-manager',
        permissionString: 'tag-manager.read'
    },
    {
        id: 6,
        label: 'MENUITEMS.SENSORMANAGMENT.TEXT',
        isTitle: true,
        permissionString: ''
    },
    {
        id: 7,
        isLayout: true
    },
    {
        id: 11,
        label: 'MENUITEMS.SCENES.TEXT',
        icon: 'bx bx-box',
        iconBgColor: '#b939c8',
        iconColor: '#f5f5f5',
        link: '/scenes',
        permissionString: 'scenes.read'
    },
    {
        id: 12,
        label: 'MENUITEMS.SITES.TEXT',
        icon: 'bx bx-building',
        iconBgColor: '#29ab93',
        iconColor: '#f5f5f5',
        link: '/site',
        permissionString: 'sites.read'
    },
    {
        id: 17,
        label: 'MENUITEMS.BR.TEXT',
        icon: 'fa fa-broadcast-tower',
        iconBgColor: '#cc3434',
        iconColor: '#f5f5f5',
        link: '/border-router',
        permissionString: 'devices.cscs.read'
    },
    {
        id: 13,
        label: 'MENUITEMS.SMARTCSCS.TEXT',
        icon: 'bx bxs-cuboid',
        iconBgColor: '#34bfcc',
        iconColor: '#f5f5f5',
        link: '/smart-csc',
        permissionString: 'devices.cscs.read'

    },
    {
        id: 14,
        label: 'MENUITEMS.ADMIN.TEXT',
        isTitle: true,
        permissionString: '*'
    },
    {
        id: 15,
        isLayout: true
    },
    {
        id: 16,
        label: 'MENUITEMS.GENERAL.TEXT',
        icon: 'fa fa-hard-hat',
        iconBgColor: '#aeaf31',
        iconColor: '#f5f5f5',
        link: '/admin/general',
        permissionString: '*'
    },
];

