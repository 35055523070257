import {Directive, TemplateRef} from '@angular/core';
import { EditableGroupDirective } from './editable-group.directive';

@Directive({
    selector: '[]'
})

export class EditableGroupActionDirective {
    constructor(public group: EditableGroupDirective) {
    }
}
