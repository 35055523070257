<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a routerLink="/" class="logo logo-dark">
                  <span class="logo-sm">
                      <img src="assets/images/logo-dark-small.png" alt="" height="22">
                  </span>
                  <span class="logo-lg">
                      <img src="assets/images/logo-dark.png" alt="" height="45">
                  </span>
                </a>
                <a routerLink="/" class="logo logo-light">
                  <span class="logo-sm">
                      <img src="assets/images/logo-light-small.png" alt="" height="22">
                  </span>
                  <span class="logo-lg">
                      <img src="assets/images/logo-light.png" alt="" height="60">
                  </span>
                 </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
                    (click)="toggleMobileMenu($event)">
                <i class="fa fa-fw fa-bars"></i>
            </button>
        </div>

        <div class="d-flex" *ngIf="user">

            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item" id="page-header-notf-dropdown" ngbDropdownToggle>
                    <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
                    class="ms-1">{{countryName}}</span>
                    <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
                    <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <!-- item-->
                    <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
                       (click)="setLanguage(item.text, item.lang, item.flag)"
                       [ngClass]="{'active': cookieValue === item.lang}">
                        <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
                        class="align-middle">{{item.text}}</span>
                    </a>
                    <!-- item-->
                </div>
            </div>

            <div class="dropdown d-none d-lg-inline-block ms-1">
                <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
                    <i class="bx bx-fullscreen"></i>
                </button>
            </div>

            <div class="dropdown d-inline-block" ngbDropdown *ngIf="notifications">
                <button type="button" class="btn header-item noti-icon"
                        id="page-header-notifications-dropdown" ngbDropdownToggle>
                    <i class="bx bx-bell" [class]="(notifications.length > 0) ? 'bx-tada' : ''"></i>
                    <span class="badge bg-danger rounded-pill" *ngIf="notifications.length > 0">
                        {{ notifications.length }}
                    </span>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                     aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
                            </div>
                            <div class="col-auto" *ngIf="notifications.length > 0">
                                <a href="javascript: void(0);" class="small" (click)="markRead('*')">
                                    {{ 'HEADER.NOTIFICATIONS.MARKREAD' | translate }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <ngx-simplebar style="position: relative; height: 350px">
                        <ng-container *ngFor="let notif of notifications" [ngSwitch]="notif.type">
                            <a class="text-reset notification-item"
                               [routerLink]="['tickets', notif.data.ticket_id]"
                               *ngSwitchCase="notificationTypes.TicketCommentAdded">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-info font-size-16">
                                        <i class="bx bxs-comment"></i>
                                    </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.COMMENT_ADDED' | translate }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1">Comment was added to a ticket you subscribed to.</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ notif.created_at | deviceConnectionTimeAgo}}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="text-reset notification-item"
                               [routerLink]="['tickets', notif.data.ticket_id]"
                               *ngSwitchCase="notificationTypes.TicketAssigned">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-info font-size-16">
                                        <i class="bx bxs-user"></i>
                                    </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.TICKET_ASSIGNED_TITLE' | translate }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.TICKET_ASSIGNED' | translate }}</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ notif.created_at | deviceConnectionTimeAgo}}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="text-reset notification-item"
                               [routerLink]="['tickets', notif.data.ticket_id]"
                               *ngSwitchCase="notificationTypes.Ticket">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-info font-size-16">
                                        <i class="bx bxs-info-circle"></i>
                                    </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.TICKET_TITLE' | translate }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.TICKET' | translate }}</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ notif.created_at | deviceConnectionTimeAgo}}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="text-reset notification-item"
                               [routerLink]="['smart-csc', notif.data.device_id]"
                               *ngSwitchCase="notificationTypes.Csc">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-info font-size-16">
                                        <i class="bx bxs-info-circle"></i>
                                    </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.CSC_TITLE' | translate }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.CSC' | translate }}</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ notif.created_at | deviceConnectionTimeAgo}}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="text-reset notification-item"
                               [routerLink]="['smart-csc', notif.data.device_id]"
                               *ngSwitchCase="notificationTypes.CscError">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-warning font-size-16">
                                        <i class="bx bxs-error"></i>
                                    </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.CSC_ERROR_TITLE' | translate }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.CSC_ERROR' | translate }}</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ notif.created_at | deviceConnectionTimeAgo}}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="text-reset notification-item"
                               [routerLink]="['scenes', notif.data.scene_id]"
                               *ngSwitchCase="notificationTypes.Scene">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-info font-size-16">
                                        <i class="bx bxs-landscape"></i>
                                    </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SCENE_TITLE' | translate }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SCENE' | translate }}</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ notif.created_at | deviceConnectionTimeAgo}}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="text-reset notification-item"
                               [routerLink]="['scenes', notif.data.scene_id]"
                               *ngSwitchCase="notificationTypes.SceneDesignChanged">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-info font-size-16">
                                        <i class="bx bxs-pencil"></i>
                                    </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SCENE_DESIGN_TITLE' | translate }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SCENE_DESIGN' | translate }}</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ notif.created_at | deviceConnectionTimeAgo}}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="text-reset notification-item"
                               [routerLink]="['scenes', notif.data.scene_id]"
                               *ngSwitchCase="notificationTypes.SceneDevicesChanged">
                                <div class="media">
                                    <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-info font-size-16">
                                        <i class="bx bx-devices"></i>
                                    </span>
                                    </div>
                                    <div class="media-body">
                                        <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SCENE_DEVICE_TITLE' | translate }}</h6>
                                        <div class="font-size-12 text-muted">
                                            <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SCENE_DEVICE' | translate }}</p>
                                            <p class="mb-0"><i class="mdi mdi-clock-outline"></i> {{ notif.created_at | deviceConnectionTimeAgo}}</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </ng-container>
                        <div class="row" style="text-align: center;" *ngIf="notifications.length == 0">
                            <p>{{ 'HEADER.NOTIFICATIONS.NONE' | translate }}</p>
                        </div>
                    </ngx-simplebar>
<!--                    <div class="p-2 border-top d-grid">-->
<!--                        <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">-->
<!--                            <i class="mdi mdi-arrow-right-circle me-1"></i>-->
<!--                            <span key="t-view-more">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</span>-->
<!--                        </a>-->
<!--                    </div>-->
                </div>
            </div>

            <div class="dropdown d-inline-block" ngbDropdown *ngIf="user">
                <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
                    <span class="d-none d-xl-inline-block ms-1">{{user.first_name}} {{user.last_name}}</span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <!-- item-->
                    <a class="dropdown-item" routerLink="/profile"><i
                        class="bx bx-user font-size-16 align-middle me-1"></i>
                        {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
                        class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                        {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
                </div>
            </div>

        </div>
    </div>
</header>
