import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {DeviceStatus} from "../models/smartcsc";

@Pipe({
    name: 'prettyIsoTime'
})
export class PrettyIsoTime implements PipeTransform {
    transform(time: Date): String {
        if(time == null)
            return "Unknown"

        let value = moment.utc(time).format("DD-MM-YYYY HH:mm:ss")

        return value;
    }
}
