export class Address {
    house: string;
    street: string;
    city: string;
    county: string;
    zip: string;
    country: string;

    constructor(house = "", street = "", city = "", county = "", zip = "", country = "")
    {
        this.house = house;
        this.street = street;
        this.city = city;
        this.county = county;
        this.zip = zip;
        this.country = country;
    }
}
